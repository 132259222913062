@tailwind base;
@tailwind components;
@tailwind utilities;

/* Over-rides to the global P&C stylesheet */
html {
  scroll-behavior: smooth;
}

#nav .contact-wrap {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.stickyButtonBar {
  /*padding-bottom: calc(22rem + env(safe-area-inset-bottom));*/
  position: fixed;
  left: 0;
  bottom: 0px;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: auto;
  padding-top: 0.5rem;
  z-index: 2;
}

@media (min-width: 768px) {
  .stickyButtonBar {
    height: auto;
    position: relative;
    left: auto;
    bottom: auto;
    /*padding-bottom: calc(0.5rem + env(safe-area-inset-bottom));*/
  }
}

/* Make check boxes bigger */
input[type="checkbox"] {
  transform: scale(1.25);
}
